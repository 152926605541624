<template>
  <ep-layout class="el-body documents-preview">
    <div class="el-panel">
      <!-- toolbar -->
      <el-toolbar>
        <el-toolbar-section>
          <button class="el-icon-button el-icon-button--big icon-previous_page" :title="$t('button.back')" @click="goBack()">
          </button>
          <h3 class="el-title" v-if="!editing">
            {{ docName }}
            <span class="el-subtitle" v-if="folder">
              /
              <template
                v-if="folder.code === 'notClassified'"
              >{{ $t('documents.categories.notClassified') }}</template>
              <template v-else>{{ folder.name }}</template>
            </span>
          </h3>
          <form v-else @submit.prevent="rename(doc, $refs.renameDoc.value)">
            <div class="el-input-group el-input-group--default-size">
              <input
                ref="renameDoc"
                type="text"
                class="el-input-group__input"
                :placeholder="$t('documents.rename.placeholder')"
                required
              />
            </div>
            <button class="el-button">
              <span class="icon-save"></span>
            </button>
            <button class="el-button el-button--inverted" @click.prevent="editing = false">
              <span class="icon-close"></span>
            </button>
          </form>
        </el-toolbar-section>
        <el-toolbar-spacer />
        <!-- button sections -->
      </el-toolbar>
      <el-toolbar-section class="toolbar">
        <!-- rename -->
        <el-rectangle-button-container :grey="true" >
          <el-rectangle-button  @click.native="downloadFile(doc)">
            <span class="icon-download"></span>
            {{ $t('documents.toolbar.button.download') }}
          </el-rectangle-button>
        </el-rectangle-button-container>
        <!-- Share -->
        <el-rectangle-button-container :grey="true">
          <el-rectangle-button @click.native="ShareMe()">
            <span class="icon-share"></span>
            {{ $t('documents.toolbar.button.share') }}
          </el-rectangle-button>
        </el-rectangle-button-container>
        <!-- Delete -->
        <el-rectangle-button-container :grey="true">
          <el-rectangle-button @click.native="showModal">
            <span class="icon-supprime"></span>
            {{ $t('documents.toolbar.button.delete') }}
          </el-rectangle-button>
        </el-rectangle-button-container>
          <el-rectangle-button-container :grey="true">
            <el-rectangle-button @click.native="editing = true">
              <span class="icon-edit"></span>
              {{ $t('documents.toolbar.button.rename') }}
            </el-rectangle-button>
        </el-rectangle-button-container>
      </el-toolbar-section>

      <!-- viewers -->
      <div class="ep-preview" ref="iframeContainer" v-if="doc.extension === 'pdf'"></div>
      <div v-else>
        <img class="ep-preview" :src="previewUrl" />
      </div>
    </div>

        <!--modal Delete-->
    <div class="el-dialog" v-show='modalVisible'>
      <div class="el-dialog__container">
        <div class="el-panel">
            <h2 className="el-title">{{$t('dialog.item.delete.file')}}</h2>
          <form v-on:submit.prevent="deleteFile(doc)">
          <div class="ep-button--panel-middle">
            <button
              class="el-button el-button--inverted"
              @click.prevent="showModal"
            >Annuler</button>
            <button
              class="el-button"
               type="submit"
            >Supprimer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ep-layout>
</template>
<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import downloadjs from 'downloadjs';
import EpLayout from '@/layouts/Default.vue';
import documentApi from '@/api/DocumentApi';
import trackingServices from '@/plugins/posthog';

export default {
  components: {
    'ep-layout': EpLayout,
  },
  props: {
    folder: {
      type: Object,
      required: false,
    },
    doc: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      docName: '',
      previewUrl: undefined,
      editing: false,
      modalVisible: false,
      params: {
        noReset: true,
        doReload: false,
      },
      previewConditions: {
        isAndroid: undefined,
        windowSize: null,
      },
    };
  },
  watch: {
    previewUrl() {
      if (this.previewUrl.length && this.doc.extension === 'pdf' && (this.previewConditions.isAndroid === false || this.previewConditions.windowSize >= 1280)) {
        const iframe = document.createElement('iframe');
        iframe.id = 'pdfViewer';
        iframe.classList.add('ed-modal__object');
        iframe.type = 'application/pdf';
        iframe.onload = () => {
          const loadScript = document.createElement('script');
          iframe.appendChild(loadScript);
        };
        this.$refs.iframeContainer.appendChild(iframe);
        iframe.src = this.previewUrl;
      }
    },
  },
  methods: {
    ...mapActions({
      getUsageDatas: 'user/getUsageDatas',
    }),
    checkPreviewConditions() {
      this.previewConditions.isAndroid = /Android/.test(navigator.userAgent);
      this.previewConditions.windowSize = window.screen.width;
    },
    ShareMe() {
      const o = [];
      o.push(this.doc);
      this.$router.push({
        name: 'sharings.create',
        params: { share: o },
      });
    },
    goBack() {
      if (this.doc.isNew) {
        this.params.doReload = true;
        this.getUsageDatas();
      }
      if (this.folder) {
        this.$router.push({ name: 'folders.index', params: this.params });
      } else {
        this.$router.push({ name: 'home.index' });
      }
    },
    downloadFile(file) {
      documentApi
        .getContent(file.id)
        .then((response) => {
          if (response) {
            this.$store.dispatch('application/openSnackbar', {
              message:
                'Veuillez patienter pendant le téléchargement des fichiers',
              type: 'success',
              time: 10,
              centered: true,
            });
            let filename = file.name;
            if (!filename.endsWith(`.${file.extension}`)) {
              filename = `${filename}.${file.extension}`;
            }
            downloadjs(response, filename);
          } else {
            this.$store.dispatch('application/openSnackbar', {
              message: 'Erreur lors du téléchargement du fichier',
              type: 'error',
              time: 10,
              centered: true,
            });
          }
        })
        .catch(() => {
          this.$store.dispatch('application/openSnackbar', {
            message: 'Erreur lors du téléchargement du fichier',
            time: 10,
            type: 'error',
            centered: true,
          });
        });
    },
    rename(doc, name) {
      documentApi.rename(doc.id, name).then(() => {
        this.params.doReload = true;
        this.docName = name;
      });
      this.editing = false;
    },
    deleteFile(file) {
      documentApi.deleteById(file.id).then((response) => {
        if (response.code === 0) {
          this.$store.dispatch('application/openSnackbar', {
            message: 'Fichier supprimé',
            type: 'success',
            time: 10,
            centered: true,
          });
          this.params.doReload = true;
          this.goBack();
        } else {
          this.$store.dispatch('application/openSnackbar', {
            message: 'Erreur lors de la suppression du fichier',
            type: 'error',
            time: 10,
            centered: true,
          });
        }
      });
    },
    showModal() {
      this.modalVisible = !this.modalVisible;
    },
  },
  mounted() {
    trackingServices.documentViewed();
    this.docName = this.doc.name;
    this.previewUrl = encodeURI(
      // eslint-disable-next-line max-len
      // `${process.env.VUE_APP_API_LOCATION}/index.php?api=UserDocument&a=getContentAsGet&sessionId=${Vue.$store.state.auth.session.token}&documentId=${this.doc.id}`,
      `${process.env.VUE_APP_API_LOCATION}/edpDoc/getDocContent?sessionId=${Vue.$store.state.auth.session.token}&documentId=${this.doc.id}`,
    );
    this.checkPreviewConditions();
  },
};
</script>
